<template>
    <div class="rounded-md flex-grow">
        <div class="focus-within:z-10">
            <label for="email" class="block text-sm font-medium leading-5 text-v3-gray-800 dark:text-v3-gray-200 mt-3">{{field.title}}</label>
            <div class="text-v3-gray-900 text-opacity-70 dark:text-white dark:text-opacity-70">
                <template v-for="option in filteredOptions">
                    <span v-if="option.value === value">
                        {{option.title}}
                    </span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {},
            field: {
                type: Object
            },
            validationFieldName: {
                type: String,
                default: 'random'
            },
            dependsOn: {
                type: String,
                default: null
            },
            dependsOnValue: {
                default: null
            },
            isOptional: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                propsVal: this.value
            }
        },
        computed: {
            filteredOptions() {
                if (this.dependsOn !== null && this.dependsOnValue === null) {
                    return;
                }

                if (this.dependsOn === null || this.dependsOnValue === null) {
                    return this.field.options;
                }

                let options = this.field.options;
                return options.filter(function (option) {
                    return option[this.dependsOn] !== null && (option[this.dependsOn]).toString() === (this.dependsOnValue).toString() ||
                        (Array.isArray(option[this.dependsOn]) && (option[this.dependsOn]).includes(parseInt(this.dependsOnValue)))
                }.bind(this));
            },
            isDisabled() {
                return this.dependsOn !== null && this.dependsOnValue === null;
            },
            required: function(){
                if(this.field.validation !== null && this.field.validation !== undefined) {
                    return this.field.validation.includes('required');
                }
                return false;
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                    this.$emit("input", value);
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
